<template>
	<v-row>
		<div
			v-if="$route.name !== 'home' && $route.name !== 'dashboard'"
			class="mb-0 pb-0 pl-2">
			<v-icon
				@click="goHome"
				color="primary"
				size="22">
				mdi-home
			</v-icon>
			<v-icon
				color="primary"
				style="font-size: 14px">
				mdi-chevron-right
			</v-icon>
		</div>
		<v-breadcrumbs
			class="pl-2 pb-1 pt-1"
			:items="getBreadcrumbs">
			<template v-slot:divider>
				<v-icon class="primary--text">mdi-chevron-right</v-icon>
			</template>
			<template v-slot:item="{ item }">
				<v-breadcrumbs-item
					ripple
					class="primary--text highlight"
					@click="handleBreadcrumb(item)">
					<v-icon
						v-if="item.title === 'Home' || item.title === 'Dashboard'"
						color="primary"
						size="22">
						mdi-home
					</v-icon>
					<span v-else>{{ item.title | ellipsis("e", ".", 10) }}</span>
				</v-breadcrumbs-item>
			</template>
		</v-breadcrumbs>
	</v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
	computed: {
		...mapGetters("breadcrumbs", {
			getBreadcrumbs: "shortCrumbsFromHistory",
		}),
		...mapGetters(["allowedCasinos", "getBreadCrumbs", "getDrawer"]),
	},
	methods: {
		goHome() {
			this.$router.push({ name: "home" });
		},
		...mapMutations("breadcrumbs", {
			goToCrumb: "goToCrumb",
		}),
		handleBreadcrumb(item) {
			this.goToCrumb(item.index);
		},
	},
};
</script>

<style scoped>
.highlight {
	position: relative;
	display: inline-block;
	cursor: pointer;
}
.highlight:after {
	content: "";
	display: block;
	margin: auto;
	height: 3px;
	width: 0px;
	border-radius: 0;
	background: transparent;
	transition: width 0.3s ease-out, background-color 0.3s ease-out;
}
.highlight:hover:after {
	width: 95%;
	background: var(--v-primary-base);
	border-radius: 3px;
}
</style>
