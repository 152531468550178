<template>
	<ImportDialog
		ref="dialog"
		title="Bulk Search"
		:widths="[500]"
		:steps="2"
		submitButtonText="Search"
		parser="objectParser"
		@submit="onImport"
		@close="onImportClose"
		no-hint
		:importDialog.sync="importDialog">
		<template #title="{ title }">
			<v-card-title
				class="primary lighten-1 font-weight-bold d-flex flex-row justify-center">
				<span class="text-h5">{{ title }}</span>
				<div
					style="
						position: absolute;
						left: 0;
						top: 0;
						transform: translate(0, 33%);
					">
					<v-tooltip
						v-if="prevSearch"
						top>
						<template #activator="{ on, attrs }">
							<v-icon
								@click="prevNav"
								size="30"
								v-on="on"
								v-bind="attrs"
								color="white"
								style="position: relative; top: 0; left: 10px; opacity: 1">
								mdi-history
							</v-icon>
						</template>
						<span>Go to previous search</span>
					</v-tooltip>
				</div>
			</v-card-title>
		</template>
		<template #drop-info="{ dragging }">
			<b
				:class="dragging ? 'kajot-text--text' : 'primary--text'"
				class="text-uppercase">
				DROP
			</b>
			<template v-if="!dragging">
				your CSV file or
				<b
					:class="dragging ? 'kajot-text--text' : 'primary--text'"
					class="text-uppercase">
					CLICK
				</b>
				here
			</template>
			to
			<b
				:class="dragging ? 'kajot-text--text' : 'primary--text'"
				class="text-uppercase">
				bulk search
			</b>
			.
		</template>
		<template #actions1-next="{ listUpload, step, next }">
			<v-btn
				:disabled="step == 1 && !listUpload"
				text
				@click="next">
				Next
			</v-btn>
		</template>
		<template #actions1-submit>&nbsp;</template>
		<template #actions2="{ cancel, prev }">
			<div>
				<v-btn
					text
					@click="prev()">
					Previous
				</v-btn>
				<v-btn
					text
					@click="cancel(true)">
					Cancel
				</v-btn>
			</div>
		</template>
		<template #step-2-header>Data</template>
		<template #step-2-content="{ parserResult }">
			<v-card
				class="elevation-0"
				flat>
				<v-card-text class="d-flex flex-column align-center">
					<v-row class="align-center w-100">
						<div
							v-for="i in 3"
							:key="i"
							class="mr-4 my-4">
							<v-checkbox
								class="mt-0"
								v-for="key in possibleKeys.slice((i - 1) * 2, i * 2)"
								:key="key.name"
								v-model="roundSearchKeys"
								:label="key.name"
								:value="key.value"></v-checkbox>
						</div>
					</v-row>
					<span
						v-html="
							$options.filters.highlight(
								`Click on Table Header for Bulk Search with the contents of the column.`,
								['Table Header', 'Bulk Search']
							)
						"></span>
					<v-data-table
						dense
						class="ma-1"
						hide-default-header
						:footerProps="{ 'items-per-page-options': [10] }"
						:headers="mapHeaders(parserResult.headers)"
						:items="parserResult.data">
						<template #header="{ props }">
							<template v-for="(header, index) of props.headers">
								<th
									:key="`${index}-header`"
									@click="findByColumn(header.text, parserResult.data)"
									v-ripple
									class="search-header">
									{{ header.text }}
								</th>
							</template>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</template>
	</ImportDialog>
</template>
<script>
import bulkSearch from "../../store/modules/bulkSearch";
import ImportDialog from "./ImportDialog.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
	components: { ImportDialog },
	data() {
		return {
			prevSearch: false,
			possibleKeys: [
				{ name: "Internal Bet", value: "bet_internal" },
				{ name: "External Bet", value: "bet_external" },
				{ name: "Internal Win", value: "win_internal" },
				{ name: "External Win", value: "win_external" },
				{ name: "Internal Round", value: "round_internal" },
				{ name: "External Round", value: "round_external" },
			],
			roundSearchKeys: [
				"bet_internal",
				"bet_external",
				"win_internal",
				"win_external",
				"round_internal",
				"round_external",
			],
		};
	},
	computed: {
		importDialog: {
			get() {
				return this.getOpen;
			},
			set(val) {
				this.setOpen(val);
			},
		},
		...mapGetters("bulkSearch", ["getOpen"]),
	},
	methods: {
		...mapMutations("bulkSearch", ["setOpen"]),
		mapHeaders(headers) {
			return (
				headers?.map((el) => {
					return {
						text: el,
						value: el,
						sortable: false,
						align: "center",
					};
				}) ?? []
			);
		},
		reinit() {
			this.step = 1;
			this.roundSearchKeys = [
				"bet_internal",
				"bet_external",
				"win_internal",
				"win_external",
				"round_internal",
				"round_external",
			];
			this.$refs.dialog.listOnCancel();
		},
		prevNav() {
			this.$router.push({
				name: "rounds",
			});
			this.reinit();
			this.setOpen(false);
		},
		async findByColumn(a, b) {
			const searchKeys = b.map((el) => el[a]);
			this.$router.push({
				name: "rounds",
				params: {
					breadcrumbs: { title: `Rounds`, name: "rounds", hard: true },
					searchKeys: searchKeys,
					searchBy: this.roundSearchKeys,
				},
			});
			this.reinit();
			this.setOpen(false);
		},
		onImportClose() {
			this.roundSearchKeys = this.possibleKeys.map((el) => el.value);
		},
		onImport({ data, headers }) {
			this.importHeaders = this.mapHeaders(headers);
			this.importData = data;
			this.importTable = true;
		},
	},
	watch: {
		importDialog(e) {
			if (e) {
				this.prevSearch = !!sessionStorage.getItem("roundSearchKeys");
			}
		},
	},
};
</script>

<style>
.search-header {
	cursor: pointer;
	border-radius: 10px;
	border: 4px solid transparent;
	background-color: var(--v-menu_background-base);
	background-clip: padding-box;
	padding: 6px 6px;
	position: relative;
	text-transform: uppercase;
}

.search-header:hover {
	background-color: var(--v-primary-base);
}
</style>
