var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _vm.$route.name !== "home" && _vm.$route.name !== "dashboard"
        ? _c(
            "div",
            { staticClass: "mb-0 pb-0 pl-2" },
            [
              _c(
                "v-icon",
                {
                  attrs: { color: "primary", size: "22" },
                  on: { click: _vm.goHome },
                },
                [_vm._v(" mdi-home ")]
              ),
              _c(
                "v-icon",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: { color: "primary" },
                },
                [_vm._v(" mdi-chevron-right ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("v-breadcrumbs", {
        staticClass: "pl-2 pb-1 pt-1",
        attrs: { items: _vm.getBreadcrumbs },
        scopedSlots: _vm._u([
          {
            key: "divider",
            fn: function () {
              return [
                _c("v-icon", { staticClass: "primary--text" }, [
                  _vm._v("mdi-chevron-right"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "v-breadcrumbs-item",
                  {
                    staticClass: "primary--text highlight",
                    attrs: { ripple: "" },
                    on: {
                      click: function ($event) {
                        return _vm.handleBreadcrumb(item)
                      },
                    },
                  },
                  [
                    item.title === "Home" || item.title === "Dashboard"
                      ? _c(
                          "v-icon",
                          { attrs: { color: "primary", size: "22" } },
                          [_vm._v(" mdi-home ")]
                        )
                      : _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("ellipsis")(item.title, "e", ".", 10))
                          ),
                        ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }