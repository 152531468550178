<template>
	<div class="app">
		<BulkSearch></BulkSearch>
		<LoganHeader class="border"></LoganHeader>
		<LoganNavigation @change:mini-variant="urobVec" />

		<v-main>
			<v-btn
				small
				width="25"
				height="25"
				v-if="miniMenu"
				class="nav_background lighten-1 font-weight-bold menuBtn animated"
				icon
				:class="{ noTouch: getDrawerLoading }"
				@click.stop="drawerSwitch">
				<v-icon
					size="18"
					:class="{ 'spin-me': getDrawer }">
					mdi-chevron-right
				</v-icon>
			</v-btn>
			<LoganBreadcrumbs
				class="mt-3 ml-1"
				:key="'router-view'"
				v-if="isMobile"></LoganBreadcrumbs>
			<transition
				name="fade-transition"
				mode="out-in"
				:duration="200"
				appear
				leave-absolute>
				<router-view
					transition="fade-transition"
					:key="'router-view'"></router-view>
			</transition>
		</v-main>
	</div>
</template>

<script>
import LoganNavigation from "../components/shared/LoganNavigation.vue";
import LoganHeader from "../components/shared/LoganHeader.vue";
import { mapActions, mapGetters } from "vuex";

import BulkSearch from "../components/shared/BulkSearch.vue";
import LoganBreadcrumbs from "../components/shared/LoganBreadcrumbs.vue";
export default {
	components: {
		LoganNavigation,
		LoganHeader,
		BulkSearch,
		LoganBreadcrumbs,
	},
	data() {
		return {
			miniMenu: undefined,
			miniMenuTimeut: undefined,
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		...mapGetters(["getDrawer", "getDrawerLoading"]),
	},
	mounted() {
		this.miniMenu = !this.getDrawer;
	},
	methods: {
		async urobVec(val) {
			if (!val) {
				clearTimeout(this.miniMenuTimeut);
				this.miniMenu = val;
				return;
			}
			this.miniMenuTimeut = setTimeout((e) => {
				this.miniMenu = val;
			}, 200);
		},
		...mapActions(["switchDrawer"]),
		drawerSwitch() {
			this.switchDrawer(!this.getDrawer);
		},
	},
};
</script>

<style scoped>
.border {
	border-bottom: 1px solid var(--v-secondary-base) !important;
}

.menuBtn {
	position: fixed;
	z-index: 6;
	top: 64px;
	transform: translate(-60%, -50%);
}

.menuBtn:hover {
	background-color: var(--v-primary-base) !important;
}

.animated {
	transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1),
		background-color 0.2s linear;
}

.spin-me {
	transform: rotate(180deg);
}
.yoink {
	transform: translate(180px, -15px);
	pointer-events: fill;
}

@media (max-width: 960px) {
	.menuBtn {
		top: 64px;
	}
}
</style>
