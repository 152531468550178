<template>
	<v-menu
		min-width="250px"
		bottom
		rounded
		origin="top right"
		nudge-bottom="53">
		<template v-slot:activator="{ on, attrs }">
			<v-avatar
				color="primary"
				:style="`${
					$route.path.includes('profile') ? `border: 2px solid transparent` : ``
				}`"
				v-bind="attrs"
				v-on="on"
				v-ripple>
				<v-img
					:src="`https://www.gravatar.com/avatar/${userEmailHash}?d=robohash`"></v-img>
			</v-avatar>
		</template>

		<v-card class="my-0">
			<v-list-item-content
				class="mx-auto justify-center align-center text-center py-2 noTouch">
				<div class="mx-auto text-center">
					<div class="my-2">
						<v-avatar
							color="primary"
							class="mb-2">
							<v-img
								:src="`https://www.gravatar.com/avatar/${userEmailHash}?d=robohash`"></v-img>
						</v-avatar>
						<h3>{{ userFullName }}</h3>
						<span class="text-caption mt-1">
							{{ userEmail }}
						</span>
					</div>
					<v-divider></v-divider>
					<div
						class="hoverZone"
						v-ripple
						@click="
							$router.push({
								name: 'my-profile',
								params: {
									user_id: userId,
									breadcrumbs: { title: `My profile`, hard: true },
								},
							})
						">
						<v-icon class="mr-3">mdi-account</v-icon>
						<span class="text-button">Profile</span>
					</div>
					<v-divider></v-divider>
					<div
						@click="darkModeChange()"
						class="d-flex flex-row justify-center hoverZone">
						<v-icon
							class="mr-3"
							@click="darkModeChange(true)"
							:color="darkModeIsOn ? 'kajot-text' : 'primary'">
							mdi-sunglasses
						</v-icon>
						<v-switch
							@click.stop.prevent="darkModeChange()"
							:input-value="$vuetify.theme.dark"
							inset
							:ripple="false"
							class="mt-0 mx-2 py-2"
							hide-details></v-switch>
						<v-icon
							@click="darkModeChange(false)"
							:color="darkModeIsOn ? 'primary' : 'kajot-text'">
							mdi-moon-waning-crescent
						</v-icon>
					</div>
					<v-divider></v-divider>
					<div
						class="hoverZone primary--text"
						v-ripple
						@click="refresh">
						<v-icon class="mr-3 primary--text">mdi-refresh</v-icon>
						<span class="text-button">Refresh Token</span>
					</div>
					<template v-if="getEnvironment === 'dev'">
						<v-divider></v-divider>
						<div
							class="hoverZone error--text"
							v-ripple
							@click="removeFooters">
							<v-icon class="mr-3 error--text">mdi-close</v-icon>
							<span class="text-button">Clear storage</span>
						</div>
					</template>
					<v-divider></v-divider>
					<div
						class="hoverZone error--text"
						v-ripple
						@click="logout">
						<v-icon class="mr-3 error--text">mdi-logout</v-icon>
						<span class="text-button">Logout</span>
					</div>
				</div>
			</v-list-item-content>
			<!-- <v-list-item-content>
				<div class="mx-auto text-center">
					<v-avatar color="primary" class="mb-3">
						<v-img
							:src="`https://www.gravatar.com/avatar/${userEmailHash}?d=robohash`"></v-img>
					</v-avatar>
					<h3>{{ userFullName }}</h3>
					<p class="text-caption mt-1">
						{{ userEmail }}
					</p>
					<v-divider class="my-3"></v-divider>
					<v-btn
						depressed
						rounded
						small
						text
						:to="{
							name: 'my-profile',
							params: {
								user_id: userId,
								breadcrumbs: { title: `My profile`, hard: true },
							},
						}">
						<v-icon> mdi-account </v-icon>
						Profile
					</v-btn>
					<v-divider class="my-3"></v-divider>
					<v-row class="d-flex justify-center align-center my-4">
						<v-btn class="mr-3 menu_background" depressed icon>
							<v-icon
								@click="darkModeChange(false)"
								:color="darkModeIsOn ? 'kajot-text' : 'primary'"
								>mdi-sunglasses</v-icon
							>
						</v-btn>
						<v-switch
							v-model="$vuetify.theme.dark"
							inset
							:ripple="false"
							class="mt-0 mx-0 pb-1"
							hide-details></v-switch>
						<v-btn class="ml-1 menu_background" depressed icon>
							<v-icon
								@click="darkModeChange(true)"
								:color="darkModeIsOn ? 'primary' : 'kajot-text'"
								>mdi-moon-waning-crescent</v-icon
							>
						</v-btn>
					</v-row>
					<template v-if="getEnvironment === 'dev'">
						<v-divider class="my-3"></v-divider>
						<v-btn depressed rounded text @click="refresh" color="primary"
							><v-icon>mdi-refresh</v-icon>Refresh Token</v-btn
						>
						<v-divider class="my-3"></v-divider>
						<v-btn depressed rounded text @click="removeFooters" color="error"
							><v-icon>mdi-close</v-icon>Clear storage</v-btn
						>
					</template>

					<v-divider class="my-3"></v-divider>
					<v-btn depressed rounded text @click="logout">
						<v-icon> mdi-logout </v-icon>
						Logout
					</v-btn>
				</div>
			</v-list-item-content> -->
		</v-card>
	</v-menu>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import MD5 from "md5";
import { constants } from "../../../constants/constants";
import ConfirmDialog2 from "../ConfirmDialog2.vue";
import CasinoMenu from "./CasinoMenu.vue";

export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters(["getEnvironment"]),
		darkModeIsOn() {
			const userSettings = JSON.parse(
				localStorage.getItem(constants.settingsLocalStorageKey)
			);
			localStorage.setItem(
				constants.settingsLocalStorageKey,
				JSON.stringify({
					...userSettings,
					...{ dark_mode: this.$vuetify.theme.dark },
				})
			);
			return this.$vuetify.theme.dark;
		},
		...mapGetters(["user", "userFullName", "userEmail", "userId"]),
		userInitials() {
			if (this.userFullName) {
				return this.userFullName
					.split(/\s+/)
					.map((s) => s.charAt(0))
					.join("")
					.toUpperCase();
			}
			return "XX";
		},
		userEmailHash() {
			if (!this.userEmail) return null;
			return MD5(this.userEmail.toLowerCase().trim());
		},
	},
	methods: {
		...mapActions("apiCall", {
			refreshTokens: "refreshTokens",
			checkExpiry: "checkTokenExpiry",
		}),
		async refresh() {
			await this.refreshTokens();
			await this.checkExpiry(true);
		},
		...mapMutations(["setRemoveFooters"]),
		removeFooters() {
			sessionStorage.removeItem(constants.footerLocalStorageKey);
			this.setRemoveFooters();
			location.reload();
		},
		darkModeChange(val) {
			if (val != undefined) {
				this.$vuetify.theme.dark = val;
				return;
			}
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
		},
		logout() {
			this.$store.dispatch("logout");
			// this.$router.push("/login");
		},
		link(path) {
			this.$router.push(path);
		},
	},
	created() {},
};
</script>

<style scoped>
.noTouch {
	user-select: none;
}

.hoverZone {
	cursor: pointer;
	position: relative;
	padding-top: 8px;
	padding-bottom: 8px;
	z-index: 10;
	transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.hoverZone::before {
	z-index: -1;
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: var(--v-kajot-text-base);
	opacity: 0;
	transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.hoverZone:hover::before {
	opacity: 0.2;
}
</style>
