import { constants } from "../constants/constants";
import { permControl } from "../constants/helpers";

export const commands = [
	{
		name: "Round Search",
		note: "Find round using Internal or External ID, BetID or WinID",
		type: "command",
		meta: "Round",
		hide: ({ userPerm }) => {
			return !permControl(userPerm, {
				key: "session.detail",
				val: constants.permissions.READ,
			});
		},
	},
	{
		name: "Session Search",
		note: "Find session using Internal or External ID, BetID or WinID",
		type: "command",
		meta: "Session",
		hide: ({ userPerm }) => {
			return !permControl(userPerm, {
				key: "session.detail",
				val: constants.permissions.READ,
			});
		},
	},
	{
		name: "Player Search",
		note: "Find player using Internal or External ID, BetID or WinID",
		type: "command",
		meta: "Player",
		hide: ({ userPerm }) => {
			return !permControl(userPerm, {
				key: "player.detail",
				val: constants.permissions.READ,
			});
		},
	},
	{
		name: "Bulk Search",
		note: "Find player using Internal or External ID, BetID or WinID",
		type: "command",
		meta: "BulkSearch",
		hide: ({ userPerm }) => {
			return !permControl(userPerm, {
				key: "session.detail",
				val: constants.permissions.READ,
			});
		},
	},
	{
		name: "Refresh Token",
		note: "Refresh permission and access token.",
		type: "command",
		meta: "RefreshToken",
	},
	{
		name: "Clear Storage",
		note: "Clear page options saved in localstorage.",
		type: "command",
		meta: "ClearStorage",
		hide: ({ isDev }) => {
			return !isDev;
		},
	},
	{
		name: "Open Locally",
		note: "Open current page in localhost",
		type: "command",
		meta: "OpenLocally",
		hide: ({ isDev }) => {
			if (window.location.hostname.includes("localhost")) return true;
			return !isDev;
		},
	},
	{
		name: "Theme Switch",
		note: "Switch theme between light and dark",
		type: "command",
		meta: "SwitchTheme",
	},
];
