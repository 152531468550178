<template>
	<div style="position: absolute">
		<v-navigation-drawer
			bottom
			:right="$vuetify.rtl"
			mobile-breakpoint="960"
			app
			dense
			:expand-on-hover="
				!getDrawer && imo?.isMouseover && !getDrawerLoading && !isMobile
			"
			:mini-variant="!getDrawer"
			permanent
			v-bind="$attrs"
			ref="navDrawer"
			style="overflow: visible"
			color="nav_background">
			<span @click.stop="drawerSwitch">
				<v-btn
					width="25"
					height="25"
					ref="menuBtn"
					v-if="!drawerStateDelayed || isMobile"
					:class="{
						touch: moButton,
						'spin-btn': getDrawer && !imo?.isMouseover && !isMobile,
					}"
					@mouseenter="mouseover(true)"
					@mouseleave="mouseover(false)"
					class="nav_background lighten-1 font-weight-bold menuBtn animated"
					icon>
					<v-icon
						size="18"
						:class="{ 'spin-me': getDrawer }">
						mdi-chevron-right
					</v-icon>
				</v-btn>
			</span>
			<div>
				<v-list>
					<div>
						<v-list-item>
							<img
								class="logoPic"
								:class="drawerState ? 'ml-2 mr-5' : 'ml-2 mr-5'"
								width="40px"
								:src="getImg('logoLogan.svg')" />
							<v-list-item-content class="pt-0 pb-0">
								<v-list-item-title
									@click="randomizeTheme"
									class="kajot-nav-text--text d-flex flex-wrap">
									<div class="bruh">Logan</div>
									<span class="bruhSub">LIGHTWEIGHT ONLINE GAMING ADMIN</span>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</div>
				</v-list>
				<v-divider
					class="primary mb-1"
					light
					style="margin: auto"
					:style="drawerState ? '' : 'max-width: 93%'" />
				<v-list
					dense
					:nav="!drawerState">
					<template v-for="item in filteredMenu">
						<template>
							<template v-if="!item.items">
								<v-list-item
									link
									:to="item.link"
									:key="item.title"
									:active-class="'primary'"
									:class="'kajot-nav-text--text '">
									<template>
										<v-list-item-icon :class="{ 'ml-2': !drawerState }">
											<v-slide-y-transition>
												<v-icon color="kajot-nav-text">{{ item.icon }}</v-icon>
											</v-slide-y-transition>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title class="kajot-nav-text--text">
												{{ item.title }}
											</v-list-item-title>
										</v-list-item-content>
									</template>
								</v-list-item>
							</template>
							<template v-else>
								<v-list-group
									dense
									:key="item.title"
									:group="item.group"
									:active-class="'primary darken-4'"
									style="margin: 1px 0px !important"
									class="kajot-nav-text--text">
									<template v-slot:activator>
										<v-list-item-icon :class="{ 'ml-2': !drawerState }">
											<v-icon color="kajot-nav-text">{{ item.icon }}</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title
												class="kajot-nav-text--text groupHeader">
												{{ item.title }}
											</v-list-item-title>
										</v-list-item-content>
									</template>
									<template v-slot:appendIcon="">
										<v-icon color="kajot-nav-text">mdi-chevron-down</v-icon>
									</template>
									<v-list-item
										link
										dense
										v-for="subItem of item.items"
										:key="subItem.title"
										:to="subItem.link"
										:active-class="'primary kajot-nav-text--text'"
										:class="
											drawerState ? `primary navgroupitem` : `navgroupitem`
										">
										<v-list-item-icon :class="{ 'ml-2': !drawerState }">
											<v-icon color="kajot-nav-text">{{ subItem.icon }}</v-icon>
											<v-icon color="kajot-nav-text">mdi-blank</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title class="kajot-nav-text--text">
												{{ subItem.title }}
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-group>
							</template>
						</template>
					</template>
				</v-list>
			</div>
			<template #append>
				<div
					class="ml-2 d-flex flex-row"
					style="
						user-select: none;
						color: white;
						font-family: Roboto, sans-serif;
						line-height: 1.5;
						font-size: 0.825rem;
						opacity: 0.7;
					">
					<div :class="`${drawerState ? 'hidden' : 'visible'}`">Version:</div>
					<div class="ml-1 version">{{ getVersion }}</div>
				</div>
			</template>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import permissions from "../../mixins/permissions";
export default {
	props: {},
	data() {
		return {
			drawerStateDelayed: false,
			drawerStateDelayedTimeout: undefined,
			imo: false,
			moButton: false,
		};
	},
	mixins: [permissions],
	computed: {
		...mapGetters([
			"getDrawer",
			"getDrawerLoading",
			"currentCasino",
			"getVersion",
		]),
		menu() {
			return [
				{
					icon: "mdi-view-dashboard",
					title: "Dashboard",
					link: {
						name: "home",
						params: {
							breadcrumbs: { title: "Dashboard", hard: true },
							casino: this.currentCasino,
						},
					},
				},
				{
					icon: "mdi-chart-bar",
					title: "Statistics",
					group: "statistics",
					collapse: false,
					items: [
						{
							title: "General",
							icon: "mdi-calendar-search",
							link: {
								name: "statistics-general",
								params: {
									breadcrumbs: { title: "General Statistics", hard: true },
								},
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "statistic",
									val: this.permissions.READ,
								}) &&
								!this._usrFlagsSome({
									key: "analytics",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Monthly",
							icon: "mdi-calendar-month",
							link: {
								name: "statistics-monthly",
								params: {
									breadcrumbs: { title: "Monthly Statistics", hard: true },
								},
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "statistic",
									val: this.permissions.READ,
								}) &&
								!this._usrFlagsSome({
									key: "analytics",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Daily",
							icon: "mdi-sun-clock",
							link: {
								name: "statistics-daily",
								params: {
									breadcrumbs: { title: "Daily Statistics", hard: true },
								},
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "statistic",
									val: this.permissions.READ,
								}) &&
								!this._usrFlagsSome({
									key: "analytics",
									val: this.permissions.READ,
								}),
						},
					],
				},
				{
					icon: "mdi-star-outline",
					title: "Bonus",
					group: "jackpots",
					collapse: false,
					items: [
						{
							title: "Groups",
							icon: "mdi-folder-multiple",
							link: {
								name: "bonus-groups",
								params: { breadcrumbs: { title: "Groups", hard: true } },
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "bonus.groups.list",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Group Configs",
							icon: "mdi-folder-cog",
							link: {
								name: "bonus-group-configs",
								params: { breadcrumbs: { title: "Group Configs", hard: true } },
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "bonus.group_configs.list",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Group Themes",
							icon: "mdi-image",
							link: {
								name: "bonus-group-themes",
								params: { breadcrumbs: { title: "Group Themes", hard: true } },
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "bonus.group_themes.list",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Bonus Config",
							icon: "mdi-star-cog",
							link: {
								name: "bonus-bonus-configs",
								params: { breadcrumbs: { title: "Bonus Config", hard: true } },
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "bonus.bonus_config.list",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Levels",
							icon: "mdi-star-box-multiple",
							link: {
								name: "bonus-levels",
								params: { breadcrumbs: { title: "Bonus Levels", hard: true } },
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "bonus.levels.list",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Paid Bonuses",
							icon: "mdi-cash",
							link: {
								name: "bonus-paid-bonuses",
								params: {
									breadcrumbs: { title: "Paid Bonuses", hard: true },
								},
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "bonus.paid_bonuses.list",
									val: this.permissions.READ,
								}),
						},
					],
				},

				{
					icon: "mdi-cash-multiple",
					title: "Free Games",
					link: {
						name: "free-games",
						params: {
							breadcrumbs: { title: "Free Games", hard: true },
							casino: this.currentCasino,
						},
					},
					hide: () =>
						!this._usrFlagsSome({
							key: "free_games.list",
							val: this.permissions.READ,
						}),
				},
				{
					icon: "mdi-account",
					title: "Players",
					link: {
						name: "players",
						params: {
							breadcrumbs: { title: "Players", hard: true },
							casino: this.currentCasino,
						},
					},
					hide: () =>
						!this._usrFlagsSome({
							key: "player.list",
							val: this.permissions.READ,
						}),
				},
				{
					icon: "mdi-table",
					title: "Sessions",
					link: {
						name: "sessions",
						params: {
							breadcrumbs: { title: "Sessions", hard: true },
							casino: this.currentCasino,
						},
					},
					hide: () =>
						!this._usrFlagsSome({
							key: "session.list",
							val: this.permissions.READ,
						}),
				},
				{
					icon: "mdi-account-wrench-outline",
					title: "Management",
					group: "management",
					collapse: false,
					items: [
						{
							title: "Users",
							icon: "mdi-account-supervisor",
							link: {
								name: "user-management",
								params: { breadcrumbs: { title: "Users", hard: true } },
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "management.users.list",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Permissions",
							icon: "mdi-account-key",
							link: {
								name: "permission-management",
								params: {
									breadcrumbs: { title: "Permission Management", hard: true },
								},
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "management.groups.list",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Casinos",
							icon: "mdi-slot-machine-outline",
							link: {
								name: "casino-management",
								params: {
									breadcrumbs: { title: "Permission Casinos", hard: true },
								},
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "management.groups.list",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Categories",
							icon: "mdi-tag",
							meta: {
								wip: true,
							},
							link: {
								name: "category-management",
								params: {
									breadcrumbs: { title: "Casino Categories", hard: true },
								},
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "meta_casinos.detail",
									val: this.permissions.READ,
								}),
						},
						{
							title: "Studios",
							icon: "mdi-office-building-cog",
							link: {
								name: "studio-management",
								params: {
									breadcrumbs: { title: "Studio Management", hard: true },
								},
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "management.groups.list",
									val: this.permissions.READ,
								}),
						},
						{
							icon: "mdi-text-box-search",
							title: "Audit",
							link: {
								name: "audit",
								params: { breadcrumbs: { title: "Audit Log", hard: true } },
							},
							hide: () =>
								!this._usrFlagsSome({
									key: "audit",
									val: this.permissions.READ,
								}),
						},
					],
				},
				{
					icon: "mdi-email",
					title: "Contact",
					link: {
						name: "contact",
						params: { breadcrumbs: { title: "Contact", hard: true } },
					},
				},
			];
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		filteredMenu() {
			return this.menu
				.filter((item) => {
					return !item.hide?.();
				})
				.map((el) => {
					if (el.items === undefined) {
						return el;
					}

					return {
						...el,
						items: el.items.filter((item) => {
							return !item.hide?.();
						}),
					};
				})
				.filter((el) => el.items === undefined || el.items.length > 0);
		},
		drawerState() {
			return this.isMobile || (!this.getDrawer && !this.imo?.isMouseover);
		},
	},
	watch: {
		drawerState(val) {
			this.$emit("change:mini-variant", val);
			if (!val) {
				clearTimeout(this.drawerStateDelayedTimeout);
				this.drawerStateDelayed = val;
				return;
			}
			this.drawerStateDelayedTimeout = setTimeout(() => {
				this.drawerStateDelayed = val;
			}, 200);
		},
	},
	methods: {
		mouseover(val) {
			this.moButton = val;
		},
		...mapActions(["switchDrawer"]),
		drawerSwitch() {
			this.switchDrawer(!this.getDrawer);
			this.$refs.menuBtn.$el.blur();
			this.$refs.navDrawer.$el.blur();
		},
		getImg(url) {
			var images = require.context("../../assets", false, /\.svg$/);
			return images("./" + url);
		},
		randomizeTheme() {
			this.$vuetify.theme.themes.dark.primary = Math.floor(
				Math.min(1, Math.random() + 0.2) * 0x888888
			);
		},
	},
	mounted() {
		this.imo = this.$refs.navDrawer;
		this.drawerStateDelayed = this.drawerState;
	},
};
</script>

<style scoped>
.bruh {
	margin-top: -6px;
	line-height: 40px;
	font-family: "COCOGOOSE", sans-serif !important;
	cursor: default !important;
	user-select: none !important;
	font-size: 30px !important;
}

.bruhSub {
	text-transform: uppercase;
	font-family: "COCOGOOSE", sans-serif !important;
	cursor: default !important;
	font-size: 7px !important;
	user-select: none !important;
}
.v-list:has(.bruh) {
	transition: padding-left 0.3s;
	padding-bottom: 8px !important;
	padding-left: 0px !important;
}

.v-list.v-sheet {
	/* transition-property: padding, margin;
	transition-duration: 0.25s; */
	transition: all 0.25s;
}

.v-list-item:has(.bruh) {
	padding-left: 0 !important;
}

.logoPic {
	transition: margin-left 0.3s, width 0.3s;
}

.noTouch {
	pointer-events: none;
}

.touch {
	pointer-events: all;
}

.animated {
	transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s linear,
		background-color 0.2s linear;
}

.spin-me {
	transform: rotate(180deg);
}

.spin-btn {
	opacity: 0;
}

.menuBtn {
	position: absolute;
	z-index: 20;
	top: 64px;
	right: 0px;
	transform: translate(40%, -50%);
}

.menuBtn:hover {
	background-color: var(--v-primary-base) !important;
}

.groupHeader {
	font-size: 0.8125rem !important;
	font-weight: 500 !important;
}

.hidden {
	max-width: 100px;
	overflow: hidden;
	animation: closing 0.18s linear forwards;
}

.visible {
	max-width: 0px;
	overflow: hidden;
	animation: opening 0.18s linear forwards;
}

@keyframes closing {
	to {
		max-width: 0px;
	}
}

@keyframes opening {
	to {
		max-width: 100px;
	}
}
</style>
