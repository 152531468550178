<template>
	<span>
		<v-overlay
			z-index="20"
			:value="searchFocused">
			<v-progress-circular
				v-if="loading"
				indeterminate
				size="100"
				color="primary"
				class="l2">
				<v-progress-circular
					indeterminate
					size="75"
					color="primary"
					class="l3"></v-progress-circular>
			</v-progress-circular>
		</v-overlay>
		<v-app-bar
			flat
			:style="`${searchFocused ? 'z-index:40' : ''}`"
			id="app-bar"
			min-height="65px"
			app
			:color="`${$vuetify.theme.dark ? `#272727` : '#373737'}`"
			class="py-1 topBar">
			<!-- :height="barH" -->

			<v-overlay
				z-index="30"
				:value="searchFocused"></v-overlay>
			<LoganBreadcrumbs v-if="!isMobile"></LoganBreadcrumbs>
			<v-spacer />

			<!-- <div
				v-click-outside="loseFocus"
				@click="setFocus"
				style="
					position: absolute;
					left: 50%;
					transform: translate(-60%);
					width: 30%;
					z-index: 60;
				">
				<v-text-field
					id="textfild"
					ref="textfild"
					hide-no-data
					hide-details
					:style="`${searchFocused ? 'z-index: 40 !important' : ''}`"
					v-model="searchKey"></v-text-field>
				<v-sheet
					z-index="60"
					style="
						position: absolute;
						top: calc(100% + 5px);
						left: 0;
						width: 100%;
						z-index: 60;
					"
					><template
						><v-list>
							<v-list-item
								@click.prevent.stop
								:input-value="index === selectedIndex"
								v-for="(item, index) in acItems"
								:key="item.note">
								<v-list-item-content>
									<v-list-item-title>
										{{ item?.item?.name.toUpperCase() }} </v-list-item-title
									><v-list-item-subtitle>
										{{ item?.item.note }}
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-chip small>{{ item?.item.type | Capitalize }}</v-chip>
							</v-list-item>
						</v-list>
					</template></v-sheet
				>
			</div> -->

			<v-autocomplete
				@keydown.delete="thisiscrazy"
				@keydown.esc="heresmynumber"
				@input="callme"
				@keydown.enter="maybe"
				@update:search-input="itshard"
				hide-no-data
				hide-details
				hide-selected
				no-filter
				item-color="primary"
				:error-messages="errors"
				:multiple="readify.length > 0"
				v-model="selected"
				dark
				full-width
				rounded
				auto-select-first
				:search-input.sync="searchKey"
				height="40"
				:style="{
					position: isMobile ? 'unset' : 'absolute',
					left: isMobile ? '0px' : '50%',
					transform: isMobile ? 'unset' : 'translate(-60%)',
					opacity: searchFocused ? '1' : '0.4',
					'z-index': searchFocused ? '40 !important' : 'unset',
					width: searchFocused ? '30%' : 'unset',
				}"
				:items="acItems"
				loader-height="0"
				class="pt-0 mt-0"
				ref="textfild"
				:placeholder="`${
					!searchFocused ? `Search (press ${isIOS ? '⌘' : 'Ctrl'} + K )` : ``
				}`">
				<template
					#prepend-inner
					v-if="command">
					<v-chip
						:class="{ error: errors.length > 0 }"
						small>
						{{ command?.item?.name }}
					</v-chip>
				</template>
				<template #append>
					<div class="d-flex flex-row align-center mt-1">
						<v-slide-y-reverse-transition>
							<v-progress-circular
								v-if="loading"
								indeterminate
								size="23"
								:color="'primary'"></v-progress-circular>
						</v-slide-y-reverse-transition>
						<v-slide-y-reverse-transition>
							<template v-if="!loading && errors?.length > 0">
								<span style="opacity: 0.5">
									<span class="error--text">{{ errors[0] }}</span>
									<v-icon
										size="20"
										color="error">
										mdi-exclamation
									</v-icon>
								</span>
							</template>
						</v-slide-y-reverse-transition>
						<v-icon
							v-if="!loading && errors?.length === 0"
							class="mb-1"
							:style="`${
								searchFocused
									? 'transform: rotate(180deg); color: var(--v-kajot-primary)'
									: ''
							}`">
							mdi-menu-down
						</v-icon>
					</div>
				</template>
				<template #item="{ item, on, attrs }">
					<v-list-item
						v-on="on"
						v-bind="attrs">
						<v-list-item-content>
							<v-list-item-title>
								{{ item?.item?.name.toUpperCase() }}
							</v-list-item-title>
							<v-list-item-subtitle>
								{{ item?.item.note.replace(`$casino$`, currentCasino) }}
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-chip small>{{ item?.item?.type | Capitalize }}</v-chip>
					</v-list-item>
				</template>
				<template #selection="{ item }">
					<v-chip small>{{ item?.item?.name }}</v-chip>
					>&nbsp;
				</template>
			</v-autocomplete>

			<v-spacer />
			<span class="mr-3">
				<CasinoMenu />
			</span>
			<UserMenu />
		</v-app-bar>
	</span>
</template>

<script>
import CasinoMenu from "./header/CasinoMenu.vue";
import UserMenu from "./header/UserMenu.vue";

import Fuse from "fuse.js";
import { computed } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isNullish } from "../../constants/helpers";
import { isIOS } from "../../constants/helpers";
import { constants } from "../../constants/constants";
import LoganBreadcrumbs from "./LoganBreadcrumbs.vue";
import localstorage from "../../mixins/localstorage";
import _ from "lodash";
import { commands } from "../../constants/commands";

export default {
	components: {
		UserMenu,
		CasinoMenu,
		LoganBreadcrumbs,
	},
	mixins: [localstorage],
	data() {
		return {
			lastCommands: [],
			command: undefined,
			readify: [],
			playerConfig: {},
			selectedIndex: 0,
			selected: undefined,
			MENU: false,
			searchIn: undefined,
			searchKey: "",
			searchRef: undefined,
			searchResult: [],
			fuse: undefined,
			errors: [],
			loading: false,
		};
	},
	created() {
		this.fuse = new Fuse(
			[
				...commands.filter(
					(el) => !el?.hide?.({ isDev: this.isDev, userPerm: this.userPerm })
				),
				...this.$router
					.getRoutes()
					.map((el) => {
						return {
							...el,
							path: el.path.replace(":casino(\\d+)", "$casino$"),
						};
					})
					.filter(
						(el) => !el.path.includes(":") && (el.meta?.commander ?? true)
					)
					.map((el) => {
						return {
							name: el.name
								.split("-")
								.map((el) => _.capitalize(el))
								.join(" "),
							note: el.path,
							type: "navigation",
						};
					}),
			],
			{
				shouldSort: true,
				threshold: 0.3,
				keys: ["name", "note"],
			}
		);
		this.$syncAndAssign(this.$route.name, "lastCommands");
		window.addEventListener("keydown", this.searchHandler);
	},
	mounted() {
		this.searchRef = this.$refs.textfild;
		this.$refs.textfild.onInput = function (e) {
			if (this.selectedIndex > -1 || !e.target) return;

			const target = e.target;
			const value = target.value;

			// If typing and menu is not currently active
			if (target.value) this.activateMenu();

			//commented out piece of vuetify shit code, that breaks normal usage
			//So much for shit code only comming from inhouse
			if (!this.multiple && !this.hasChips && value === "")
				this.deleteCurrentItem();

			this.internalSearch = value;
			this.badInput = target.validity && target.validity.badInput;
		}.bind(this.$refs.textfild);
	},
	beforeDestroy() {
		window.removeEventListener("keydown", this.searchHandler);
	},
	setup() {
		const searchKeys = ["Round", "Session", "Player"];
		const getters = computed(() =>
			mapGetters(searchKeys.map((e) => `${e.toLowerCase()}Search`))
		);
		const actions = computed(() =>
			mapActions(searchKeys.map((e) => `load${e}Search`))
		);
		return {
			searchKeys,
			...getters.value,
			...actions.value,
		};
	},
	computed: {
		...mapGetters(["getEnvironment", "userPerm"]),
		isDev() {
			return this.getEnvironment === "dev";
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		isIOS,
		acItems() {
			if (this.readify && this.readify.length > 0) {
				return [...this.readify];
			}
			if (this.searchResult && this.searchResult.length > 0) {
				const res = [...this.searchResult];
				return res;
			}
			if (["command"].includes(this.command?.item?.type)) {
				return [];
			}
			if (isNullish(this.searchKey) || this.searchKey?.length === 0) {
				return this.lastCommands.length > 0
					? [{ header: "Recent" }, ...this.lastCommands]
					: [];
			}
			const nav = this.fuse.search(this.searchKey ?? "");
			const tmpCommands = nav.filter((el) => el.item.type === "command");
			const tmpNavigation = nav.filter((el) => el.item.type === "navigation");
			const commands =
				tmpCommands.length > 0 ? [{ header: "Commands" }, ...tmpCommands] : [];
			const navigation =
				tmpNavigation.length > 0
					? [{ header: "Navigation" }, ...tmpNavigation]
					: [];
			return [...commands, ...navigation];
		},
		...mapGetters("breadcrumbs", {
			getBreadcrumbs: "shortCrumbsFromHistory",
		}),
		...mapGetters([
			"allowedCasinos",
			"getBreadCrumbs",
			"getDrawer",
			"currentCasino",
		]),
		searchFocused() {
			return this.searchRef?.isFocused ?? false;
		},
		barH() {
			let h = (window.innerHeight / 100) * 7.87;
			return h;
		},
	},
	methods: {
		...mapActions("apiCall", {
			refreshT: "refreshTokens",
		}),
		...mapMutations(["setRemoveFooters"]),
		themeChange(val) {
			if (val != undefined) {
				this.$vuetify.theme.dark = val;
				return;
			}
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
		},
		async refreshTokens() {
			await this.refreshT();
			await this.checkExpiry(true);
		},
		clearStorage() {
			sessionStorage.removeItem(constants.footerLocalStorageKey);
			this.setRemoveFooters();
			location.reload();
		},
		errorTimeout() {
			setTimeout(() => {
				this.errors = [];
			}, 1500);
		},
		async thisiscrazy(e) {
			if (isNullish(this.searchKey) || this.searchKey === "") {
				await this.$nextTick();
				this.command = undefined;
				this.selected = undefined;
				await this.$nextTick();
				this.$refs.textfild.focus();
				this.$refs.textfild.activateMenu();
			}
		},
		heresmynumber() {
			this.clearSearchBar();
		},
		itshard(e) {
			this.errors = [];
		},
		mapPlayer(el) {
			return {
				item: {
					name: el.nick
						? `${el.nick} (${el.player_mongo_id})`
						: el.player_mongo_id,
					note: `Player ID: ${el.player_id}`,
					type: "player",
					meta: el,
				},
			};
		},
		mapSession(el) {
			return {
				item: {
					name: `${el.session_mongo_id} (by ${el.player_mongo_id})`,
					note: `Game: ${el.game_name}`,
					type: "session",
					meta: el,
				},
			};
		},
		mapRound(el) {
			const humanify = (val) => {
				return this.$options.filters.Number(val, {
					currency: el.currency,
					minor_units: el.minor_units,
				});
			};
			return {
				item: {
					name: `${el.session_mongo_id}-${el.round_id}`,
					note: `Bet: ${humanify(el.bet)}, Win: ${humanify(el.win)}`,
					type: "round",
					meta: el,
				},
			};
		},
		async callme(e) {
			if (this.readify.length > 0) return;
			if (!e || !e.item || !e.item.type) {
				console.warn("How did we get here?", e);
				return;
			}
			if (["command", "navigation"].includes(e.item.type)) {
				if (e.item.type === "navigation") {
					e.item.note = e.item.note.replace("$casino$", this.currentCasino);
				}
				this.lastCommands = this.lastCommands
					.filter((el) => el.item.name != e.item.name)
					.slice(0, 4);
				this.lastCommands.unshift(e);
			}
			if (e.item.type === "command") {
				await this.$nextTick();
				this.command = e;
				this.searchKey = "";
				this.selected = undefined;
				await this.$nextTick();
				this.$refs.textfild.focus();
				this.$refs.textfild.activateMenu();
			}
			if (this.searchKeys.map((el) => el.toLowerCase()).includes(e.item.type)) {
				await this.$nextTick();
				this.clearSearchBar();
				this[`${e.item.type}Routing`](e.item.meta);
				return;
			}
			if (e.item.type === "navigation") {
				const re = /:\w*[^/]/g;
				const params = e.item.note?.match(re);
				if (e.item.note.includes(":")) {
					this.readify = params
						.map((el) =>
							el
								.substring(1)
								.split("_")
								.map((el) => _.capitalize(el))
								.join(" ")
						)
						.map((el) => {
							return {
								item: {
									name: el,
									note: `Route argument: ${el}`,
									type: "arg",
									meta: el,
								},
							};
						});
					await this.$nextTick();
					this.selected = [...this.readify];
					return;
				}
				await this.$nextTick();
				this.clearSearchBar();
				this.$router.push(e.item.note.replace("$casino$", this.currentCasino));
				return;
			}
			if (this.command && this.command?.item?.meta === "BulkSearch") {
				await this.$nextTick();
				this.clearSearchBar();
				this.setOpen(true);
				return;
			}
			if (this.command && this.command?.item?.meta === "RefreshToken") {
				await this.$nextTick();
				this.clearSearchBar();
				this.refreshTokens();
				return;
			}
			if (this.command && this.command?.item?.meta === "ClearStorage") {
				await this.$nextTick();
				this.clearSearchBar();
				this.clearStorage();
				return;
			}
			if (this.command && this.command?.item?.meta === "SwitchTheme") {
				await this.$nextTick();
				this.clearSearchBar();
				this.themeChange();
				return;
			}
		},
		async maybe(e) {
			//LEAVE IF MORE THEN ONE ITEM
			if (this.readify.length > 0) return;
			//OR ISNT COMMAND
			if (!this.command) return;
			//OR COMMAND ALREADY PERFORMED
			if (this.searchResult.length > 0) return;

			if (this.searchKey.trim() === "") {
				this.errors = ["Search key can not be empty"];
				return;
			}
			//ELSE PERFORM COMMAND SEARCH
			await this.$nextTick();
			if (this.command) {
				this.loading = true;
				await this.doSearch(this.command.item.meta);
				this.loading = false;
				this.searchKey = "";
				await this.$nextTick();
			}
		},

		...mapMutations("bulkSearch", ["setOpen"]),
		...mapMutations("breadcrumbs", {
			goToCrumb: "goToCrumb",
		}),
		...mapActions(["loadBulkRoundSearch"]),

		moveSearch() {
			this.searchIn = ++this.searchIn % this.searchKeys.length; //this.searchIn > 1 ? 0 : this.searchIn + 1;
		},
		roundRouting(round) {
			this.$router.push({
				name: "session-detail",
				params: {
					session_id: round.session_mongo_id,
					casino: this.currentCasino,
				},
				query: {
					page: round.page_number,
					highlightId: round.round_id,
				},
			});
		},
		sessionRouting(session) {
			this.$router.push({
				name: "session-detail",
				params: {
					session_id: session.session_mongo_id,
					casino: this.currentCasino,
				},
			});
		},
		playerRouting(player) {
			this.$router.push({
				name: "player-detail",
				params: {
					player_id: player.player_mongo_id,
					casino: this.currentCasino,
				},
			});
		},
		async clearSearchBar(blur = true) {
			this.command = undefined;
			this.selected = undefined;
			this.searchKey = "";
			this.searchResult = [];
			await this.$nextTick();
			this.errors = [];
			this.loading = false;
			this.command = undefined;
			if (blur) {
				this.searchRef.blur();
			}
		},
		async doSearch(meta) {
			await this[`load${meta}Search`]({
				searchKey: this.searchKey.trim(),
				noErr: true,
			});

			const koko = this[`${meta.toLowerCase()}Search`]();

			const uniqueNames = _.uniqWith(koko.data, (a, b) => {
				delete a.match_key;
				delete b.match_key;
				return _.isEqual(a, b);
			});

			if (uniqueNames?.length > 1) {
				this.searchResult = uniqueNames.map((el) => {
					return this[`map${meta}`](el);
				});
				return;
			}
			if (!uniqueNames || uniqueNames?.length === 0) {
				this.searchKey = "";
				this.errors = [`${this.$options.filters.Capitalize(meta)} not found`];
				this.errorTimeout();
				return;
			}
			if (uniqueNames?.length === 1) {
				this.clearSearchBar();
				this[`${meta.toLowerCase()}Routing`](uniqueNames[0]);
				return;
			}
			await this.$nextTick();
			this.$refs.textfild.focus();
		},
		searchHandler(e) {
			if (e.key == "k" && (e.ctrlKey || e.metaKey)) {
				e.preventDefault();

				this.$refs.textfild.focus();
				this.$refs.textfild.activateMenu();
			}
		},

		handleBreadcrumb(item) {
			this.goToCrumb(item.index);
		},
		...mapActions(["switchDrawer"]),
		drawerSwitch() {
			this.switchDrawer(!this.getDrawer);
		},
	},
	watch: {
		searchFocused(val) {
			if (!val) {
				this.clearSearchBar();
			}
		},
		command: {
			handler(nVal, oVal) {
				if (oVal?.item?.type !== "command") {
					return;
				}
				if (nVal === undefined) {
					this.clearSearchBar(false);
				}
			},
			deep: true,
		},
	},
};
</script>

<style scoped>
.v-input__slot::before {
	opacity: 0 !important;
}

.topBar {
	z-index: 6;
}

.v-list-item--active::before {
	content: "";
	background-color: var(--v-primary-base);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.v-list-item:hover::before {
	content: "";
	background-color: var(--v-primary-base);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
</style>
