var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c("BulkSearch"),
      _c("LoganHeader", { staticClass: "border" }),
      _c("LoganNavigation", { on: { "change:mini-variant": _vm.urobVec } }),
      _c(
        "v-main",
        [
          _vm.miniMenu
            ? _c(
                "v-btn",
                {
                  staticClass:
                    "nav_background lighten-1 font-weight-bold menuBtn animated",
                  class: { noTouch: _vm.getDrawerLoading },
                  attrs: { small: "", width: "25", height: "25", icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.drawerSwitch.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      class: { "spin-me": _vm.getDrawer },
                      attrs: { size: "18" },
                    },
                    [_vm._v(" mdi-chevron-right ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isMobile
            ? _c("LoganBreadcrumbs", {
                key: "router-view",
                staticClass: "mt-3 ml-1",
              })
            : _vm._e(),
          _c(
            "transition",
            {
              attrs: {
                name: "fade-transition",
                mode: "out-in",
                duration: 200,
                appear: "",
                "leave-absolute": "",
              },
            },
            [
              _c("router-view", {
                key: "router-view",
                attrs: { transition: "fade-transition" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }