var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "absolute" } },
    [
      _c(
        "v-navigation-drawer",
        _vm._b(
          {
            ref: "navDrawer",
            staticStyle: { overflow: "visible" },
            attrs: {
              bottom: "",
              right: _vm.$vuetify.rtl,
              "mobile-breakpoint": "960",
              app: "",
              dense: "",
              "expand-on-hover":
                !_vm.getDrawer &&
                _vm.imo?.isMouseover &&
                !_vm.getDrawerLoading &&
                !_vm.isMobile,
              "mini-variant": !_vm.getDrawer,
              permanent: "",
              color: "nav_background",
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "ml-2 d-flex flex-row",
                        staticStyle: {
                          "user-select": "none",
                          color: "white",
                          "font-family": "Roboto, sans-serif",
                          "line-height": "1.5",
                          "font-size": "0.825rem",
                          opacity: "0.7",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: `${_vm.drawerState ? "hidden" : "visible"}`,
                          },
                          [_vm._v("Version:")]
                        ),
                        _c("div", { staticClass: "ml-1 version" }, [
                          _vm._v(_vm._s(_vm.getVersion)),
                        ]),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "v-navigation-drawer",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.drawerSwitch.apply(null, arguments)
                },
              },
            },
            [
              !_vm.drawerStateDelayed || _vm.isMobile
                ? _c(
                    "v-btn",
                    {
                      ref: "menuBtn",
                      staticClass:
                        "nav_background lighten-1 font-weight-bold menuBtn animated",
                      class: {
                        touch: _vm.moButton,
                        "spin-btn":
                          _vm.getDrawer &&
                          !_vm.imo?.isMouseover &&
                          !_vm.isMobile,
                      },
                      attrs: { width: "25", height: "25", icon: "" },
                      on: {
                        mouseenter: function ($event) {
                          return _vm.mouseover(true)
                        },
                        mouseleave: function ($event) {
                          return _vm.mouseover(false)
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          class: { "spin-me": _vm.getDrawer },
                          attrs: { size: "18" },
                        },
                        [_vm._v(" mdi-chevron-right ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-list", [
                _c(
                  "div",
                  [
                    _c(
                      "v-list-item",
                      [
                        _c("img", {
                          staticClass: "logoPic",
                          class: _vm.drawerState ? "ml-2 mr-5" : "ml-2 mr-5",
                          attrs: {
                            width: "40px",
                            src: _vm.getImg("logoLogan.svg"),
                          },
                        }),
                        _c(
                          "v-list-item-content",
                          { staticClass: "pt-0 pb-0" },
                          [
                            _c(
                              "v-list-item-title",
                              {
                                staticClass:
                                  "kajot-nav-text--text d-flex flex-wrap",
                                on: { click: _vm.randomizeTheme },
                              },
                              [
                                _c("div", { staticClass: "bruh" }, [
                                  _vm._v("Logan"),
                                ]),
                                _c("span", { staticClass: "bruhSub" }, [
                                  _vm._v("LIGHTWEIGHT ONLINE GAMING ADMIN"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-divider", {
                staticClass: "primary mb-1",
                staticStyle: { margin: "auto" },
                style: _vm.drawerState ? "" : "max-width: 93%",
                attrs: { light: "" },
              }),
              _c(
                "v-list",
                { attrs: { dense: "", nav: !_vm.drawerState } },
                [
                  _vm._l(_vm.filteredMenu, function (item) {
                    return [
                      [
                        !item.items
                          ? [
                              _c(
                                "v-list-item",
                                {
                                  key: item.title,
                                  class: "kajot-nav-text--text ",
                                  attrs: {
                                    link: "",
                                    to: item.link,
                                    "active-class": "primary",
                                  },
                                },
                                [
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      { class: { "ml-2": !_vm.drawerState } },
                                      [
                                        _c(
                                          "v-slide-y-transition",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "kajot-nav-text",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.icon))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass: "kajot-nav-text--text",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                ],
                                2
                              ),
                            ]
                          : [
                              _c(
                                "v-list-group",
                                {
                                  key: item.title,
                                  staticClass: "kajot-nav-text--text",
                                  staticStyle: { margin: "1px 0px !important" },
                                  attrs: {
                                    dense: "",
                                    group: item.group,
                                    "active-class": "primary darken-4",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-list-item-icon",
                                              {
                                                class: {
                                                  "ml-2": !_vm.drawerState,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "kajot-nav-text",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.icon))]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "kajot-nav-text--text groupHeader",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.title) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "appendIcon",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "kajot-nav-text",
                                                },
                                              },
                                              [_vm._v("mdi-chevron-down")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                _vm._l(item.items, function (subItem) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: subItem.title,
                                      class: _vm.drawerState
                                        ? `primary navgroupitem`
                                        : `navgroupitem`,
                                      attrs: {
                                        link: "",
                                        dense: "",
                                        to: subItem.link,
                                        "active-class":
                                          "primary kajot-nav-text--text",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        { class: { "ml-2": !_vm.drawerState } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "kajot-nav-text",
                                              },
                                            },
                                            [_vm._v(_vm._s(subItem.icon))]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "kajot-nav-text",
                                              },
                                            },
                                            [_vm._v("mdi-blank")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "kajot-nav-text--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(subItem.title) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                      ],
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }